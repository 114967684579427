import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import NumberFormat from 'react-number-format';
import SvgIcon from 'components/svgIcon';
import FirstOmni from 'contracts/json/FirstOmni.json';
import {
  addTokenToMetamask,
  switchCurrentNetwork,
  getNetworkBasicNameById,
} from 'contracts/browserWallet';
import { claimFromClaimer } from 'contracts/claimerContract';
import { OMNI_DEAL_ADDRESS } from 'models/omniClaimerModel';
import './index.scss';
import {
  ETHEREUM_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  BASE_CHAIN_ID,
  MERLIN_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  XDAI_CHAIN_ID,
  XUSDT_ADDRESS,
  USDT_ADDRESS,
} from 'constants/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNotification,
  setActiveHashes,
  setSharedNotification,
  setUserDeal,
  updateLoading,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { getMerkleProof } from 'services/apiService';
import { claimFromDeal } from '../../../contracts/dealV2Contract';

const checkIfThereIsClaimableAmount = (claimableAmounts) =>
  claimableAmounts.some(({ claimAmount }) => +claimAmount > 0);

const getDefaultClaimChainId = (deal) => {
  const firstClaimableClaimer = deal.claimers.find(({ claimableAmounts }) =>
    checkIfThereIsClaimableAmount(claimableAmounts)
  );

  if (firstClaimableClaimer) {
    return firstClaimableClaimer.chainId;
  }

  if (checkIfThereIsClaimableAmount([deal.dealClaimInfo])) {
    return deal.chainId;
  }

  return ARBITRUM_CHAIN_ID;
};

const ClaimDealModal = ({ open, deal, onClose }) => {
  const [isPending, setPending] = useState(false);
  const [claimNetworkChainId, setClaimNetworkChainId] = useState(getDefaultClaimChainId(deal));
  const [activeClaimer, setActiveClaimer] = useState(null);
  const [claimTokenAddress, setClaimTokenAddress] = useState(null);
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const { chainId, activeHashes } = globalReducer;
  const { accountInfo } = authReducer;
  const dispatch = useDispatch();

  const addToMetamask = async (token) => {
    const { chainId: claimerChainId } = activeClaimer;
    const networkChecked = await switchCurrentNetwork(chainId, claimerChainId);

    if (networkChecked) {
      setTimeout(() => {
        addTokenToMetamask(token.address, token.symbol, token.decimals);
      }, 500);
    }
  };

  const onClaim = async () => {
    const { chainId: claimerChainId } = activeClaimer;

    const networkChecked = await switchCurrentNetwork(chainId, claimerChainId);
    const networkBasicName = getNetworkBasicNameById(activeClaimer.chainId);
    let tx;

    if (networkChecked) {
      setPending(true);
      dispatch(updateLoading(true));
      if (claimerChainId === XDAI_CHAIN_ID && deal.dealClaimInfo) {
        tx = await claimFromDeal(deal, [XUSDT_ADDRESS]);
      } else if (claimerChainId === ARBITRUM_CHAIN_ID && deal.dealClaimInfo) {
        tx = await claimFromDeal(deal, [USDT_ADDRESS]);
      } else {
        const merkleProof =
          deal.address === OMNI_DEAL_ADDRESS
            ? FirstOmni.claims[ethers.utils.getAddress(accountInfo.address)]
            : await getMerkleProof(activeClaimer.id);

        tx = await claimFromClaimer(
          deal,
          claimTokenAddress,
          accountInfo.address,
          networkBasicName,
          merkleProof
        );
      }
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: networkBasicName,
              callback: async () => {
                if (
                  (claimNetworkChainId === XDAI_CHAIN_ID ||
                    claimNetworkChainId === ARBITRUM_CHAIN_ID) &&
                  deal.dealClaimInfo
                ) {
                  deal.dealClaimInfo.claimAmount = '0.0';
                } else {
                  const claimer = deal.claimers.find(
                    ({ chainId: claimerChainId }) => claimerChainId === activeClaimer.chainId
                  );
                  const claimableAmount = claimer.claimableAmounts.find(
                    ({ token }) => token.address === claimTokenAddress
                  );
                  claimableAmount.claimAmount = '0.0';
                }
                dispatch(setUserDeal(deal));
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: networkBasicName,
            dealAddress: deal.address,
            dealImage: deal.imageUrl,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      } else {
        dispatch(
          setSharedNotification({
            status: 'error',
            title: 'Error',
            description: 'Something went wrong',
          })
        );
      }
      setPending(false);
      onClose();
      dispatch(updateLoading(false));
    }
  };

  useEffect(() => {
    let claimerToSet;
    setClaimTokenAddress(null);

    if (deal.dealClaimInfo) {
      claimerToSet = {
        tokenAddress: deal.dealClaimInfo.token.address,
        chainId: claimNetworkChainId,
        token: deal.dealClaimInfo.token,
        claimable: deal.dealClaimInfo.claimAmount,
      };
    } else {
      claimerToSet = deal.claimers.find((claimer) => claimer.chainId === claimNetworkChainId);
    }

    setActiveClaimer(claimerToSet ? { ...claimerToSet } : null);
  }, [claimNetworkChainId, deal]);

  const hasDealRefundForChainId = (dealToCheck, chainIdToCheck) =>
    +dealToCheck.dealClaimInfo?.claimAmount > 0 && dealToCheck.chainId === chainIdToCheck;

  const hasAvailableClaimsForChainId = (dealToCheck, chainIdToCheck) =>
    dealToCheck.claimers.some(
      ({ claimableAmounts, chainId: claimerChainId }) =>
        checkIfThereIsClaimableAmount(claimableAmounts) && claimerChainId === chainIdToCheck
    );

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="claim-deal-modal">
        <div className="claim-deal-modal__head">
          <div>
            <RoundedAvatar src={deal.imageUrl} />
            <div>
              <span>{deal.name}</span>
              <CustomProgressBar total={Number(deal.dealSize)} value={Number(deal.raisedAmount)} />
            </div>
          </div>
          <div>
            <RoundedButton disabled={isPending} onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton
              type="secondary"
              disabled={isPending || !claimTokenAddress}
              onClick={onClaim}
            >
              Claim
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <div className="claim-deal-modal__claimers">
          {hasAvailableClaimsForChainId(deal, ETHEREUM_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === ETHEREUM_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(ETHEREUM_CHAIN_ID)}
            >
              Ethereum
            </button>
          )}
          {hasAvailableClaimsForChainId(deal, BSC_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === BSC_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(BSC_CHAIN_ID)}
            >
              BSC
            </button>
          )}
          {hasAvailableClaimsForChainId(deal, POLYGON_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === POLYGON_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(POLYGON_CHAIN_ID)}
            >
              POLYGON
            </button>
          )}
          {hasDealRefundForChainId(deal, XDAI_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === XDAI_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(XDAI_CHAIN_ID)}
            >
              xDai
            </button>
          )}
          {(hasDealRefundForChainId(deal, ARBITRUM_CHAIN_ID) ||
            hasAvailableClaimsForChainId(deal, ARBITRUM_CHAIN_ID)) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === ARBITRUM_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(ARBITRUM_CHAIN_ID)}
            >
              Arbitrum
            </button>
          )}
          {hasAvailableClaimsForChainId(deal, AVALANCHE_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === AVALANCHE_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(AVALANCHE_CHAIN_ID)}
            >
              AVALANCHE
            </button>
          )}
          {hasAvailableClaimsForChainId(deal, BASE_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === BASE_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(BASE_CHAIN_ID)}
            >
              BASE
            </button>
          )}
          {hasAvailableClaimsForChainId(deal, MERLIN_CHAIN_ID) && (
            <button
              type="button"
              className={`network-selection-button
          ${claimNetworkChainId === MERLIN_CHAIN_ID ? ' active' : ''}`}
              onClick={() => setClaimNetworkChainId(MERLIN_CHAIN_ID)}
            >
              MERLIN
            </button>
          )}
        </div>
        <div className="claim-deal-modal__table">
          <div className="claim-deal-modal__table-head">
            <span>Claim</span>
            <span>Token</span>
            <span>Balance</span>
            <span>Add Token</span>
          </div>
          <div className="claim-deal-modal__table-body">
            {deal.dealClaimInfo && (
              <div className="token">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        XUSDT_ADDRESS === claimTokenAddress || USDT_ADDRESS === claimTokenAddress
                      }
                      onClick={() =>
                        setClaimTokenAddress(
                          deal.chainId === XDAI_CHAIN_ID ? XUSDT_ADDRESS : USDT_ADDRESS
                        )
                      }
                      disabled={deal.dealClaimInfo?.claimAmount === '0.0'}
                    />
                  }
                />
                <span>{deal.dealClaimInfo?.token.name}</span>
                <span>
                  <NumberFormat
                    value={Number(deal.dealClaimInfo?.claimAmount).toFixed(2)}
                    thousandSeparator
                    displayType="text"
                  />
                </span>
                <button
                  className="metamask-button"
                  type="button"
                  onClick={() => addToMetamask(deal.dealClaimInfo?.token)}
                >
                  <SvgIcon name="metamaskFox" />
                </button>
              </div>
            )}
            {activeClaimer?.claimableAmounts?.map(({ claimAmount, token }) => (
              <div className="token" key={token.address}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={token.address === claimTokenAddress}
                      onClick={() => setClaimTokenAddress(token.address)}
                      disabled={claimAmount === '0.0'}
                    />
                  }
                />
                <span>{token.name}</span>
                <span>
                  <NumberFormat
                    value={Number(claimAmount).toFixed(2)}
                    thousandSeparator
                    displayType="text"
                  />
                </span>
                <button
                  className="metamask-button"
                  type="button"
                  onClick={() => addToMetamask(token)}
                >
                  <SvgIcon name="metamaskFox" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ClaimDealModal.propTypes = {
  open: PropTypes.bool,
  deal: PropTypes.shape(),
  onClose: PropTypes.func,
};

ClaimDealModal.defaultProps = {
  open: false,
  deal: {},
  onClose: () => {},
};

export default ClaimDealModal;
