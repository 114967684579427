import { ethers } from 'ethers';
import { XDAI_CHAIN_ID } from 'constants/config';

import { getProviderByChainId } from 'contracts/providers';
import { getAvailableClaimAmountFromClaimer } from '../contracts/claimerContract';
import { getTokenInfo } from '../contracts/erc20';

const formatClaimAmount = (claimAmounts, tokenInfo) =>
  claimAmounts.lt(ethers.utils.parseUnits('1', tokenInfo.decimals))
    ? '0.0'
    : ethers.utils.formatUnits(claimAmounts, tokenInfo.decimals);

const getClaimerClaimAmountsForToken = async (
  provider,
  claimerAddress,

  tokenAddress,
  walletAddress,
  dealContributionAmount
) => {
  try {
    const claimAmounts = await getAvailableClaimAmountFromClaimer(
      provider,
      claimerAddress,
      walletAddress,
      dealContributionAmount,
      tokenAddress
    );

    const tokenInfo = await getTokenInfo(provider, tokenAddress);

    return {
      claimAmount: formatClaimAmount(claimAmounts, tokenInfo),
      token: {
        address: tokenAddress,
        ...tokenInfo,
      },
    };
  } catch (error) {
    return { claimAmount: '0.0', token: null };
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getClaimerData = async (deal, walletAddress) => {
  const claimers = await Promise.all(
    deal.claimers?.map(async (claimer) => {
      if (!claimer.tokens.length && claimer.chainId !== +XDAI_CHAIN_ID) {
        return { ...claimer, claimableAmounts: [], token: null };
      }

      const provider = getProviderByChainId(claimer.chainId);

      const claimableAmoutsPromises = claimer.tokens.map(({ address }) =>
        getClaimerClaimAmountsForToken(
          provider,
          claimer.address,
          address,
          walletAddress,
          deal.contributedAmount
        )
      );

      return {
        ...claimer,
        claimableAmounts: await Promise.all(claimableAmoutsPromises),
      };
    })
  );

  return claimers;
};
