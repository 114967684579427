/* eslint-disable no-unreachable */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import './index.scss';
import RoundedButton from 'components/button/rounded-button';
import { updateCloudMessagingToken, updateNotificationSettings } from 'services/apiService';
import { useNotifications } from 'hooks';
import { getToken } from 'services/firebase';
import { isSupported } from 'firebase/messaging';

const NotificationManagement = ({ onGetUser }) => {
  const authReducer = useSelector((state) => state.auth);
  const { enabledNotifications, cloudMessagingToken } = authReducer;
  const [notificationState, setNotificationState] = useState({ ...enabledNotifications });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMessagingSupported, setIsMessagingSupported] = useState(false);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  useEffect(() => {
    (async () => {
      setIsMessagingSupported(await isSupported());
    })();
  }, []);

  useEffect(() => {
    setNotificationState({ ...enabledNotifications });
  }, [enabledNotifications]);

  const handleChange = async (type, channel, e) => {
    if (channel === 'browser' && !isMessagingSupported) {
      showErrorNotification('Push notifications not supported in this browser');
      return;
    }
    const isPermissionGranted = isMessagingSupported && Notification?.permission === 'granted';
    const { checked } = e.target;
    if (channel === 'browser' && (!cloudMessagingToken || !isPermissionGranted) && checked) {
      try {
        const token = await getToken();

        if (token) {
          await updateCloudMessagingToken(token);
          await onGetUser();
        } else {
          showErrorNotification(
            'Please enable notification for this site to recieve push notifications'
          );
          return;
        }
      } catch (error) {
        showErrorNotification('Please enable notifications for this site first');
        return;
      }
    }

    setNotificationState((prev) => ({
      ...prev,
      [type]: { ...prev[type], [channel]: checked },
    }));
  };

  const isDirty = useMemo(
    () =>
      Object.entries(notificationState).some(([typeKey]) =>
        Object.entries(notificationState[typeKey]).some(
          ([channelKey, value]) => value !== enabledNotifications[typeKey][channelKey]
        )
      ),
    [notificationState, enabledNotifications]
  );

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await updateNotificationSettings(notificationState);
      await onGetUser();
      showSuccessNotification('Notification settings saved successfully!');
    } catch (error) {
      showErrorNotification('Something went wrong while saving notification settings!');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="box-info profile-notification-management">
      <table>
        <tr>
          <th>Type of Notification</th>
          <th>Email</th>
          <th>Browser</th>
        </tr>
        <tr>
          <td>
            <span>New pledge opened</span>
          </td>
          <td>
            <Checkbox
              checked={notificationState?.pledge?.email || false}
              onChange={(e) => handleChange('pledge', 'email', e)}
            />
          </td>
          <td>
            <Checkbox
              checked={notificationState?.pledge?.browser || false}
              onChange={(e) => handleChange('pledge', 'browser', e)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>New deal live</span>
          </td>
          <td>
            <Checkbox
              checked={notificationState?.deal?.email || false}
              onChange={(e) => handleChange('deal', 'email', e)}
            />
          </td>
          <td>
            <Checkbox
              checked={notificationState?.deal?.browser || false}
              onChange={(e) => handleChange('deal', 'browser', e)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>New distribution</span>
          </td>
          <td>
            <Checkbox
              checked={notificationState?.distribution?.email || false}
              onChange={(e) => handleChange('distribution', 'email', e)}
            />
          </td>
          <td>
            <Checkbox
              checked={notificationState?.distribution?.browser || false}
              onChange={(e) => handleChange('distribution', 'browser', e)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>FCFS starts in 30 min</span>
          </td>
          <td>
            <Checkbox
              checked={notificationState?.fcfs?.email || false}
              onChange={(e) => handleChange('fcfs', 'email', e)}
            />
          </td>
          <td>
            <Checkbox
              checked={notificationState?.fcfs?.browser || false}
              onChange={(e) => handleChange('fcfs', 'browser', e)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>New invoice generated</span>
          </td>
          <td>
            <Checkbox
              checked={notificationState?.invoice?.email || false}
              onChange={(e) => handleChange('invoice', 'email', e)}
            />
          </td>
          <td>
            <Checkbox
              checked={notificationState?.invoice?.browser || false}
              onChange={(e) => handleChange('invoice', 'browser', e)}
            />
          </td>
        </tr>
      </table>
      <div className="profile-notification-management__submit">
        <RoundedButton type="primary" onClick={onSubmit} disabled={!isDirty || isSubmitting}>
          Update
        </RoundedButton>
      </div>
    </div>
  );
};

export default NotificationManagement;
