import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSystemPart } from 'store/actions';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import SeedTable from './SeedTable';
import remote from './remote';
import './index.scss';
import RoundedButton from 'components/button/rounded-button';
import CreateSeedDialog from './SeedTable/CreateSeedDialog';

const DistroWallets = () => {
  const [isCreateSeedModalOpen, setIsCreateSeedModalOpen] = useState(false);
  const openCreateSeedModal = () => setIsCreateSeedModalOpen(true);
  const closeCreateSeedModal = () => setIsCreateSeedModalOpen(false);

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DISTRO_MANAGER));
  }, [dispatch]);

  const { data: seeds } = remote.useGetAllSeeds();
  if (!seeds) return null;

  return (
    <div className="distro-page">
      <div className="deals-header">
        <div className="deals-header-left d-flex vertical-center">
          <div className="deals-header__title vertical-center">Seeds</div>
          {seeds && (
            <div className="deals-header__deals-cnt vertical-center">
              <span>{seeds.length} Total</span>
            </div>
          )}
          <RoundedButton onClick={openCreateSeedModal} className="action-button new-seed-button">
            <span>New seed</span>
          </RoundedButton>
        </div>
      </div>
      <SeedTable seeds={seeds} />
      <CreateSeedDialog isModalOpen={isCreateSeedModalOpen} closeModal={closeCreateSeedModal} />
    </div>
  );
};

export default DistroWallets;
