import React from 'react';
import { formatAddress } from 'utils';
import { getAddressLinkByChainName } from 'contracts/explorers';

const AddressLink = ({ chainName, chainAddress }) => {
  if (!chainName || !chainAddress) return null;
  return (
    <a
      onClick={(e) => e.stopPropagation()}
      href={getAddressLinkByChainName(chainName, chainAddress)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {formatAddress(chainAddress)}
    </a>
  );
};

export default AddressLink;
