import React, { useState } from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import remote from '../../remote';
import './index.scss';

const initialFormState = {
  name: '',
  hash: '',
};

const CreateSeedDialog = ({ isModalOpen, closeModal }) => {
  const createNewSeed = remote.usePostNewSeed();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const clearErrorMessage = () => setErrorMessage('');

  const [formData, setFormData] = useState(initialFormState);
  const updateForm = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const closeModalCb = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setFormData(initialFormState);
    closeModal();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await createNewSeed.mutateAsync({ name: formData.name, hash: formData.hash });
      setSuccessMessage(`Seed ${formData.name} created!`);
      setTimeout(closeModalCb, 1000);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message);
    }
  };

  const disableFormSubmit = createNewSeed.isLoading || !formData.name || !formData.hash;

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModalCb}
      classes={{ paper: 'seed-modal' }}
      disableBackdropClick
    >
      <DialogTitle>New seed</DialogTitle>
      <Divider />
      <DialogContent>
        {!!errorMessage && <h5 className="message message--error">{errorMessage}</h5>}
        {!!successMessage && <h5 className="message message--success">{successMessage}</h5>}
        <form id="seed-form" onSubmit={handleFormSubmit}>
          <div className="input-holder">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onFocus={clearErrorMessage}
              onChange={updateForm}
              placeholder="Enter name"
            />
          </div>

          <div className="input-holder">
            <label>Encrypted</label>
            <input
              type="text"
              name="hash"
              value={formData.hash}
              onFocus={clearErrorMessage}
              onChange={updateForm}
              placeholder="Enter encrypted mnemonic"
            />
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className="action-buttons">
          <RoundedButton
            className="action-button action-button--close"
            onClick={closeModalCb}
            disabled={createNewSeed.isLoading}
          >
            Close
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            buttonType="submit"
            disabled={disableFormSubmit}
          >
            Create
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSeedDialog;
