import React, { useEffect, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeAccountInfoAnimation,
  updateGlobal,
  openSidebar,
  closeSidebar,
  setPollList,
} from 'store/actions';
import NumberFormat from 'react-number-format';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import { Box } from '@material-ui/core';
import Tooltip from 'components/tooltip';
import DownloadUserAnalytics from 'features/sidebar/DownloadUserAnalytics';
import './index.scss';
import { getAllAdminPolls, getAllUserPolls } from 'services/apiService';
import { getDealMenuItems, getOtherMenuItems, ROLES } from './utils';

const unlockedRoutes = ['/profile', '/verify-email'];

function LeftSideBar() {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { accountInfo, isAdmin, email, role, features } = authReducer;
  const {
    activeDeal,
    notificationDropdownOpened,
    accountInfoAnimation,
    isSidebarExpanded,
    userDeals,
    pollList,
    systemPart,
  } = globalReducer;
  const history = useHistory();

  const clearOverlay = () => {
    if (activeDeal || notificationDropdownOpened) {
      dispatch(
        updateGlobal({
          activeDeal: false,
          notificationDropdownOpened: false,
        })
      );
    }
  };

  const isAppLocked = (!email || !email?.isVerified) && !isAdmin;

  const fetchPolls = useCallback(() => {
    (async () => {
      const getPolls = isAdmin ? getAllAdminPolls : getAllUserPolls;
      const res = await getPolls();
      dispatch(setPollList(res));
    })();
  }, [isAdmin, dispatch]);

  useEffect(() => {
    fetchPolls();
  }, [fetchPolls]);

  useEffect(() => {
    if (
      accountInfo.address &&
      !isAdmin &&
      isAppLocked &&
      !unlockedRoutes.includes(history.location.pathname)
    ) {
      history.push('/profile');
    }
  });

  const getAnimateClass = (val) => {
    if (
      accountInfoAnimation &&
      accountInfoAnimation.fields &&
      (accountInfoAnimation?.fields.includes(val) ||
        accountInfoAnimation?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  const isKycVerified =
    authReducer.idVerified === 'verification_approved' || authReducer.idVerified === 'approved';

  const getStatusTooltipText = () => {
    if (!isKycVerified && !email?.isVerified) {
      return "Haven't KYC-ed and no e-mail added or verified.";
    }
    if (!isKycVerified) return "Haven't KYC-ed.";
    if (!email?.isVerified) return 'No e-mail added or verified.';
    return '';
  };

  useEffect(() => {
    if (accountInfoAnimation && accountInfoAnimation.fields) {
      setTimeout(() => {
        dispatch(removeAccountInfoAnimation());
      }, [3000]);
    }
  }, [accountInfoAnimation, dispatch]);

  const closeSidebarIfMobile = () => {
    if (window.innerWidth < 700) dispatch(closeSidebar());
  };

  return (
    <div
      className={`sidebar ${!isSidebarExpanded ? 'sidebar--collapsed' : ''}`}
      onClick={clearOverlay}
    >
      <div className="sidebar__top vertical-center">
        <div className="chart-btn-container">
          <IconButton
            icon={isSidebarExpanded ? 'menuExpanded' : 'menuCollapsed'}
            onClick={() => dispatch(isSidebarExpanded ? closeSidebar() : openSidebar())}
          />
        </div>
      </div>
      <div className="sidebar__content">
        <div className="sidebar-menu">
          {getDealMenuItems(userDeals, isAdmin).map((item) => {
            if (
              (!features?.includes(item.featureName) && !item.restrictTo?.includes(role)) ||
              !item.systemParts?.includes(systemPart)
            )
              return null;
            return (
              <NavLink
                key={item.id}
                to={item.path}
                exact={item.path === '/'}
                activeClassName="sidebar-menu__item--active"
                className={`sidebar-menu__item ${
                  isAppLocked && !unlockedRoutes.includes(item.path)
                    ? 'sidebar-menu__item-disabled'
                    : ''
                } vertical-center`}
                onClick={closeSidebarIfMobile}
              >
                <div className="d-flex">
                  <span className="sidebar-menu__item-icon vertical-center">
                    <SvgIcon name={item.icon} />
                  </span>
                  {isSidebarExpanded && (
                    <>
                      <span className="sidebar-menu__item-name button-big">{item.name}</span>
                      {item.label}
                    </>
                  )}
                </div>
              </NavLink>
            );
          })}
        </div>
        <div className="sidebar-border" />
        <div className="sidebar-menu">
          {getOtherMenuItems(pollList, isAdmin).map((item) => {
            if (
              (!features?.includes(item.featureName) && !item.restrictTo?.includes(role)) ||
              !item.systemParts?.includes(systemPart)
            )
              return null;
            return (
              <NavLink
                key={item.id}
                to={item.path}
                activeClassName="sidebar-menu__item--active"
                className={`sidebar-menu__item ${
                  isAppLocked && !unlockedRoutes.includes(item.path)
                    ? 'sidebar-menu__item-disabled'
                    : ''
                } vertical-center`}
                onClick={closeSidebarIfMobile}
              >
                <div className="d-flex">
                  <span className="sidebar-menu__item-icon vertical-center">
                    <SvgIcon name={item.icon} />
                  </span>
                  {isSidebarExpanded && (
                    <>
                      <span className="sidebar-menu__item-name button-big">{item.name}</span>
                      {item.label}
                    </>
                  )}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
      <div className="sidebar__footer">
        {isSidebarExpanded &&
          (role === ROLES.ADMIN || features?.includes('download analytics') ? (
            <DownloadUserAnalytics />
          ) : (
            role &&
            !isAdmin && (
              <div className="account-info" key={JSON.stringify(accountInfo)}>
                <div className="account-info__field">
                  <h5 className="account-info__field-name body-1">Status</h5>
                  <div className="account-info__field-value">
                    {isKycVerified && email?.isVerified ? (
                      <>
                        Approved <SvgIcon name="iconDealsCheckmarkClicked" />
                      </>
                    ) : (
                      <>
                        Action Required{' '}
                        <Tooltip title={getStatusTooltipText()}>
                          <div>
                            <IconButton
                              onClick={() => history.push('profile')}
                              icon="iconDealsUserCancel"
                            />
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
                <div className="account-info__field">
                  <h5 className="account-info__field-name body-1">
                    Locked
                    <Tooltip
                      title={
                        <div>
                          <Box display="flex" flexDirection="column" mt={1}>
                            <span>Access Level</span>
                            <span className="account-info__tooltip-value">
                              {accountInfo.userAccessLevel >= 0
                                ? `Level ${accountInfo.userAccessLevel}`
                                : '-'}
                            </span>
                          </Box>
                          <Box display="flex" flexDirection="column" mt={1}>
                            <span>Minimum Pro-Rata Share </span>
                            <span className="account-info__tooltip-value">{`${Number(
                              accountInfo.proRataShare
                            ).toFixed(4)} %`}</span>
                          </Box>
                        </div>
                      }
                    >
                      <div>
                        <SvgIcon name="help" />
                      </div>
                    </Tooltip>
                  </h5>
                  <div
                    className={`account-info__field-value ${getAnimateClass('lockInfo.amount')}`}
                  >
                    <NumberFormat
                      value={Math.floor(Number(accountInfo?.lockInfo?.amount))}
                      thousandSeparator
                      displayType="text"
                      suffix=" BDT"
                    />
                    {Math.floor(Number(accountInfo?.lockInfo?.amount)) >= 250 &&
                    accountInfo?.userAccessLevel >= 0 ? (
                      <SvgIcon name="iconDealsCheckmarkClicked" />
                    ) : (
                      <Tooltip title={accountInfo?.userAccessLevel >= 0 ? '' : 'Re-Lock required'}>
                        <div>
                          <IconButton
                            onClick={() => history.push(isAppLocked ? 'profile' : 'lockup')}
                            icon="iconDealsUserCancel"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="account-info__field">
                  <h5 className="account-info__field-name body-1">Wallet balance</h5>
                  <div className={`account-info__field-value ${getAnimateClass('xUsdtBalance')}`}>
                    <NumberFormat
                      value={Math.floor(Number(accountInfo.usdtBalance))}
                      thousandSeparator
                      displayType="text"
                      suffix=" USDT"
                    />
                    {Math.floor(Number(accountInfo.usdtBalance)) >= 250 ? (
                      <SvgIcon name="iconDealsCheckmarkClicked" />
                    ) : (
                      <IconButton
                        onClick={() => history.push('profile')}
                        icon="iconDealsUserCancel"
                      />
                    )}
                  </div>
                </div>
                <div className="account-info__field">
                  <h5 className="account-info__field-name body-1">Contribution Allowance</h5>
                  <div className={`account-info__field-value ${getAnimateClass('xUsdtBalance')}`}>
                    <NumberFormat
                      value={Math.floor(Number(accountInfo.usdtAllowance))}
                      thousandSeparator
                      displayType="text"
                      suffix=" USDT"
                    />
                    {Math.floor(Number(accountInfo.usdtAllowance)) > 0 ? (
                      <SvgIcon name="iconDealsCheckmarkClicked" />
                    ) : (
                      <IconButton
                        onClick={() => history.push('allowance')}
                        icon="iconDealsUserCancel"
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          ))}
        <div className="meta-info">
          <div className="social-links">
            <a href="https://discord.gg/mfUvxDbs9h" target="_blank" rel="noopener noreferrer">
              <div>
                <SvgIcon name="discord" width={16} />
              </div>
            </a>
            <a href="https://t.me/BlackDragon_Announce" target="_blank" rel="noopener noreferrer">
              <div>
                <SvgIcon name="telegram" width={16} />
              </div>
            </a>
            <a href="https://twitter.com/blackdragon_io" target="_blank" rel="noopener noreferrer">
              <div>
                <SvgIcon name="twitter" width={16} />
              </div>
            </a>
            <a href="mailto:hello@blackdragon.io" target="_blank" rel="noopener noreferrer">
              <div>
                <SvgIcon name="mail" width={16} />
              </div>
            </a>
          </div>
          {isSidebarExpanded && (
            <>
              <div className="terms-and-policy">
                <div className="policy">
                  <a
                    href="https://blackdragon.io/legal/PrivacyPolicy.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="terms">
                  <a
                    href="https://blackdragon.io/legal/TermsOfService.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </div>
              </div>
              <div className="terms-and-policy">
                <div className="policy">
                  <a
                    href="https://blackdragon.io/legal/KYCPolicy.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    KYC Policy
                  </a>
                </div>
                <div className="user-guide">
                  <a
                    href="https://docs.blackdragon.io/"
                    className="user-guide-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Guide
                    <SvgIcon name="iconExternalLinkSmall" width={6} />
                  </a>
                </div>
              </div>
              <div className="company-info">© 2021 BlackDragon</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeftSideBar;
