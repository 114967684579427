import { ethers } from 'ethers';
import {
  XDAI_CHAIN_ID,
  USDT_ADDRESS,
  XUSDT_ADDRESS,
  ETHEREUM_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  BASE_CHAIN_ID,
  MERLIN_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
} from 'constants/config';
import {
  getAvailableClaimAmountFromDeal,
  getDealInfo,
  isWalletWhitelisted,
  getMyContributionAmountLeft,
  getMyContributionAmount,
} from '../contracts/dealContract';
import { getCurrentPhaseInfo } from '../contracts/dealV2Contract';
import { getTokenInfo } from '../contracts/erc20';
import { getClaimerData } from './claimerModel';
import { getDealStatusName } from './dealStatusModel';
import { getProviderByChainId } from '../contracts/providers';
import { OMNI_DEAL_ADDRESS, OMNI_FIRST_CLAIMERS, makeOmniClaimer } from './omniClaimerModel';

const CLOSED_STATUS_ID = 3;
const CANCELED_STATUS_ID = 4;
const DISTRIBUTED_STATUS_ID = 5;

const formatClaimAmount = (claimAmounts, tokenInfo) =>
  claimAmounts.lt(ethers.utils.parseUnits('1', tokenInfo.decimals))
    ? '0.0'
    : ethers.utils.formatUnits(claimAmounts, tokenInfo.decimals);

const getDealClaimAmountsForToken = async (provider, dealAddress, tokenAddress, walletAddress) => {
  try {
    const claimAmounts = await getAvailableClaimAmountFromDeal(
      provider,
      dealAddress,
      walletAddress,
      tokenAddress
    );
    const tokenInfo = await getTokenInfo(provider, tokenAddress);

    return {
      claimAmount: formatClaimAmount(claimAmounts, tokenInfo),
      token: {
        address: tokenAddress,
        ...tokenInfo,
      },
    };
  } catch (error) {
    return { claimAmount: '0.0', token: null };
  }
};

const parseClaimerModels = (claimers, dealAddress, wallet) => {
  if (
    wallet &&
    dealAddress === OMNI_DEAL_ADDRESS &&
    OMNI_FIRST_CLAIMERS.some((x) => x.toLowerCase() === wallet.toLowerCase())
  ) {
    // HACK FOR OMNI
    claimers = [makeOmniClaimer()];
  }

  const result = {};

  const ethClaimer = claimers.find((c) => c.chainId === ETHEREUM_CHAIN_ID);
  if (ethClaimer) {
    result.ethClaimerAddress = ethClaimer.address;
    result.ethClaimerCreationBlock = ethClaimer.number;
    result.ethClaimerTokens = ethClaimer.tokens;
    result.ethClaimerId = ethClaimer.id;
    result.ethMerkleRoot = ethClaimer.root;
  }

  const bscClaimer = claimers.find((c) => c.chainId === BSC_CHAIN_ID);
  if (bscClaimer) {
    result.bscClaimerAddress = bscClaimer.address;
    result.bscClaimerCreationBlock = bscClaimer.number;
    result.bscClaimerTokens = bscClaimer.tokens;
    result.bscClaimerId = bscClaimer.id;
    result.bscMerkleRoot = bscClaimer.root;
  }

  const polygonClaimer = claimers.find((c) => c.chainId === POLYGON_CHAIN_ID);
  if (polygonClaimer) {
    result.polygonClaimerAddress = polygonClaimer.address;
    result.polygonClaimerCreationBlock = polygonClaimer.number;
    result.polygonClaimerTokens = polygonClaimer.tokens;
    result.polygonClaimerId = polygonClaimer.id;
    result.polygonMerkleRoot = polygonClaimer.root;
  }

  const avalancheClaimer = claimers.find((c) => c.chainId === AVALANCHE_CHAIN_ID);
  if (avalancheClaimer) {
    result.avalancheClaimerAddress = avalancheClaimer.address;
    result.avalancheClaimerCreationBlock = avalancheClaimer.number;
    result.avalancheClaimerTokens = avalancheClaimer.tokens;
    result.avalancheClaimerId = avalancheClaimer.id;
    result.avalancheMerkleRoot = avalancheClaimer.root;
  }

  const baseClaimer = claimers.find((c) => c.chainId === BASE_CHAIN_ID);
  if (baseClaimer) {
    result.baseClaimerAddress = baseClaimer.address;
    result.baseClaimerCreationBlock = baseClaimer.number;
    result.baseClaimerTokens = baseClaimer.tokens;
    result.baseClaimerId = baseClaimer.id;
    result.baseMerkleRoot = baseClaimer.root;
  }

  const merlinClaimer = claimers.find((c) => c.chainId === MERLIN_CHAIN_ID);
  if (merlinClaimer) {
    result.merlinClaimerAddress = merlinClaimer.address;
    result.merlinClaimerCreationBlock = merlinClaimer.number;
    result.merlinClaimerTokens = merlinClaimer.tokens;
    result.merlinClaimerId = merlinClaimer.id;
    result.merlinMerkleRoot = merlinClaimer.root;
  }

  const arbitrumClaimer = claimers.find((c) => c.chainId === ARBITRUM_CHAIN_ID);
  if (arbitrumClaimer) {
    result.arbitrumClaimerAddress = arbitrumClaimer.address;
    result.arbitrumClaimerCreationBlock = arbitrumClaimer.number;
    result.arbitrumClaimerTokens = arbitrumClaimer.tokens;
    result.arbitrumClaimerId = arbitrumClaimer.id;
    result.arbitrumMerkleRoot = arbitrumClaimer.root;
  }

  return result;
};

const isClaimable = (deal) =>
  [CLOSED_STATUS_ID, CANCELED_STATUS_ID, DISTRIBUTED_STATUS_ID].includes(deal.statusId);

const getDealClaimInfo = async (deal, walletAddress) => {
  const refundTokenAddress = +deal.chainId === XDAI_CHAIN_ID ? XUSDT_ADDRESS : USDT_ADDRESS;

  const dealClaimInfo = await getDealClaimAmountsForToken(
    getProviderByChainId(deal.chainId),
    deal.address,
    refundTokenAddress,
    walletAddress
  );

  return dealClaimInfo;
};

export async function handleContributions(deal, accountInfo) {
  const { address: walletAddress, userAccessLevel, relockMessage } = accountInfo;

  if (
    (relockMessage && relockMessage.length && userAccessLevel === -1) ||
    deal.raisedAmount === deal.dealSize ||
    (deal.minAccessLevel === 4 && !deal.userWhitelisted) ||
    (deal.minAccessLevel !== 4 && deal.minAccessLevel > userAccessLevel)
  ) {
    return '0';
  }

  const personalCap = await getMyContributionAmountLeft(deal.address, walletAddress);
  if (
    parseFloat(personalCap) === 0 &&
    deal.allocationModel === 'Personal Cap' &&
    parseFloat(deal.userCap) <= parseFloat(deal.contributedAmount)
  ) {
    return '0';
  }

  return personalCap;
}

export const getDealMetadata = async (dealApi, accountInfo) => {
  // NOTE: deal has not been deployed yet
  if (!dealApi.address) {
    return dealApi;
  }

  const dealAddress = dealApi.address;
  const walletAddress = accountInfo.address;

  const deal = {
    ...dealApi,
    ...parseClaimerModels(dealApi.claimers, dealAddress, walletAddress),
    status: getDealStatusName(dealApi.statusId),
  };

  // NOTE: Handle claimable deals and skip loading current phase data
  if (isClaimable(deal)) {
    const dealClaimInfo =
      deal.statusId === CANCELED_STATUS_ID ? await getDealClaimInfo(deal, walletAddress) : null;

    const claimers = await getClaimerData(deal, walletAddress);

    return {
      ...deal,
      dealClaimInfo,
      claimers,
    };
  }

  const provider = getProviderByChainId(deal.chainId);

  const dealChainData = await getDealInfo(deal.address, provider);

  deal.contributedAmount = await getMyContributionAmount(provider, dealAddress, walletAddress);

  // NOTE: handle older platform deals
  if (!deal.isV2) {
    deal.personalCap = await handleContributions(deal, accountInfo);

    if (dealChainData.minAccessLevel === 4) {
      deal.userWhitelisted = await isWalletWhitelisted(
        getProviderByChainId(deal.chainId),
        dealAddress,
        walletAddress
      );
    }

    return {
      ...deal,
      ...dealChainData,
    };
  }

  const currentPhaseInfo = await getCurrentPhaseInfo(provider, deal, walletAddress);

  if (dealChainData.minAccessLevel === 4 || dealChainData.minVieLevel === 4) {
    deal.userWhitelisted = deal.userWhitelisted || !!+deal.personalCap;
  }

  return {
    ...deal,
    ...dealChainData,
    ...currentPhaseInfo,
  };
};

const getCurrentPhaseData = async (deal, walletAddress) => {
  if (!deal.isV2) return {};

  const currentPhaseInfo = await getCurrentPhaseInfo(
    getProviderByChainId(deal.chainId),
    deal,
    walletAddress
  );
  return currentPhaseInfo;
};

export async function getAdminDealMetadata(dealApi) {
  // NOTE: deal has not been deployed yet
  if (!dealApi.address) {
    return dealApi;
  }

  const provider = getProviderByChainId(dealApi.chainId);
  const dealAddress = dealApi.address;

  // HACK: removed for speed improvement
  // Not used by new deals
  const whitelists = []; // await getDealWhitelistLogs(provider, dealAddress, dealApi.creationBlockNumber);

  const deal = {
    ...dealApi,
    ...parseClaimerModels(dealApi.claimers, dealAddress, null),
    status: (dealApi.vestingStatus ?? dealApi.status).toLowerCase(),
    whitelists,
  };

  if (deal.statusId !== 1) {
    return deal;
  }

  const dealChainData = await getDealInfo(deal.address, provider);
  const currentPhaseInfo = await getCurrentPhaseData(deal);

  return {
    ...deal,
    ...dealChainData,
    ...currentPhaseInfo,
  };
}

// TODO: step2 : get deals of the connected account
export async function getAdminDealModels(apiDeals) {
  const deals = await Promise.all(apiDeals.map(getAdminDealMetadata));

  deals.forEach((d) => (d.id = d.id.toString()));

  return deals.sort((a, b) => b.creationBlockNumber - a.creationBlockNumber);
}

// TODO: step2 : get deals of the connected account
export async function getDealModels(authState, apiDeals) {
  let deals = await Promise.all(apiDeals.map((d) => getDealMetadata(d, authState.accountInfo)));
  deals.forEach((d) => (d.id = d.id.toString()));

  deals = deals.filter(
    ({ minViewLevel, userWhitelisted, contributedAmount, minAccessLevel }) =>
      (minViewLevel || 0) <= authState.accountInfo.userViewLevel ||
      (minViewLevel === 4 && userWhitelisted) ||
      (minAccessLevel === 4 && userWhitelisted) ||
      !!+contributedAmount
  );

  return deals.sort((a, b) => b.creationBlockNumber - a.creationBlockNumber);
}
