import React from 'react';

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const SYSTEM_PARTS = {
  DEALS: 'deals',
  DISTRO_MANAGER: 'distro manager',
  USER_MANAGER: 'user manager',
  INVOICES: 'invoices',
  PROFILE_SETTINGS: 'profile settings',
  VIEW_AS_USER: 'view as user',
};

export const getSystemPartsBaseRoute = (systemPart, features) => {
  const dealMenuItems = getDealMenuItems();
  const systemPartMenuItem = features?.includes('distribution')
    ? { path: '/distros' }
    : dealMenuItems.find(
        (menuItem) =>
          menuItem.systemParts?.includes(systemPart) &&
          features?.includes(menuItem.name.toLowerCase())
      );
  const distroManagerMenuItemPath = systemPartMenuItem ? systemPartMenuItem.path : '/distros';

  switch (systemPart) {
    case SYSTEM_PARTS.DISTRO_MANAGER:
      return distroManagerMenuItemPath;
    case SYSTEM_PARTS.USER_MANAGER:
      return '/role-management';
    default:
      return '/';
  }
};

export const getDealMenuItems = (userDeals, isAdmin) => [
  {
    id: 0,
    name: 'My Deals',
    featureName: 'my deals',
    path: '/',
    icon: 'myChart',
    restrictTo: [ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 1,
    name: isAdmin ? 'Deals' : 'Live Deals',
    featureName: 'deals list',
    path: '/deals',
    icon: 'chart',
    label: userDeals?.some(
      (deal) =>
        (deal.status === 'opened' || deal.status === 'paused') &&
        ![0, 6, 7, 8].includes(deal.statusId)
    ) && (
      <div className="sidebar-menu__item-label">
        <div className="sidebar-menu__item-label-green-dot" />
        <span className="sidebar-menu__item-label-text">Live</span>
      </div>
    ),
    restrictTo: [ROLES.ADMIN, ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 2,
    name: 'Distros',
    featureName: 'distribution',
    path: '/distros',
    icon: 'iconLayers',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DISTRO_MANAGER],
  },
  {
    id: 3,
    name: 'History',
    featureName: 'distribution',
    path: '/history',
    icon: 'iconTimePast',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DISTRO_MANAGER],
  },
  {
    id: 4,
    name: 'Pledges',
    featureName: 'deals list',
    path: '/pledges',
    icon: 'iconPledges',
    restrictTo: [ROLES.ADMIN, ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 5,
    name: 'Drafts',
    featureName: 'deals list',
    path: '/drafts',
    icon: 'iconDraftGray',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 6,
    name: 'Whitelists',
    featureName: 'whitelists',
    path: '/whitelists',
    icon: 'iconWhitelistGreen',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 7,
    name: 'Custom Claims',
    featureName: 'custom claims',
    path: '/custom-claims',
    icon: 'iconSettingsSliders',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DISTRO_MANAGER],
  },
  {
    id: 8,
    name: 'Wallets',
    featureName: 'balances',
    path: '/wallets',
    icon: 'iconWallet',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DISTRO_MANAGER],
  },
  {
    id: 9,
    name: 'Seeds',
    featureName: 'seeds and wallets',
    path: '/seeds',
    icon: 'iconKey',
    restrictTo: [ROLES.ADMIN],
    systemParts: [SYSTEM_PARTS.DISTRO_MANAGER],
  },
];

export const getOtherMenuItems = (pollList) => [
  {
    id: 0,
    name: 'Lockup',
    featureName: 'lockup',
    path: '/lockup',
    icon: 'iconLockupGreen',
    restrictTo: [ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 1,
    name: 'Allowance',
    featureName: 'allowance',
    path: '/allowance',
    icon: 'iconLockupGreen',
    restrictTo: [ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 4,
    name: 'Polls',
    featureName: 'polls',
    path: '/polls',
    icon: 'iconPollGray',
    label: pollList?.some((poll) => poll.status === 'Active') && (
      <div className="sidebar-menu__item-label">
        <div className="sidebar-menu__item-label-green-dot" />
        <span className="sidebar-menu__item-label-text">Live</span>
      </div>
    ),
    restrictTo: [ROLES.ADMIN, ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 5,
    name: 'My Analytics',
    featureName: 'my analytics',
    path: '/analytics',
    icon: 'iconAnalytics',
    restrictTo: [ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
  {
    id: 6,
    name: 'Dashboard',
    featureName: 'dashboard',
    path: '/dashboard',
    icon: 'iconDashboard',
    restrictTo: [ROLES.ADMIN, ROLES.USER],
    systemParts: [SYSTEM_PARTS.DEALS],
  },
];
