import { ethers } from 'ethers';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatAddress } from 'utils/helpers';
import jwtDecode from 'jwt-decode';
import {
  updateAuth,
  setChainId,
  removeActiveHash,
  setSharedNotification,
  setResolvedNotification,
  setActiveHashes,
  updateNotification,
  updateGlobal,
} from 'store/actions';
import { loadUserModel } from 'models/userModel';
import { getProviderByChainName } from 'contracts/providers';
import { ADMIN_ADDRESS, DEAL_FACTORY_ADDRESS } from 'constants/index';
import { useLoadDeals } from 'hooks';
import { getUser } from 'services/apiService';
import DealFactoryArtifact from 'contracts/abis/DealFactory.json';

export const dealFactoryInterface = new ethers.utils.Interface(DealFactoryArtifact.abi);

const useLoadAppData = (loadWeb3Modal, logoutOfWeb3Modal) => {
  const globalReducer = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const { walletAddress, token } = authReducer;
  const { activeHashes, notifications } = globalReducer;
  const { fetchUserDeals } = useLoadDeals();

  useEffect(() => {
    if (token && localStorage.getItem('token_exp')) {
      const msToLogout = +localStorage.getItem('token_exp') * 1000 - Date.now();
      const maxPossibleTimeout = 2147483647;
      if (msToLogout < 0) {
        logoutOfWeb3Modal();
        return;
      }

      const logoutTimeoutId = setTimeout(
        () => {
          logoutOfWeb3Modal();
        },
        msToLogout > maxPossibleTimeout ? maxPossibleTimeout : msToLogout
      );

      // eslint-disable-next-line consistent-return
      return () => {
        clearTimeout(logoutTimeoutId);
      };
    }
  }, [token, logoutOfWeb3Modal]);

  useEffect(() => {
    if (!walletAddress || !token) return;
    if (walletAddress.toLowerCase() !== jwtDecode(token).wallet.toLowerCase()) {
      logoutOfWeb3Modal();
      return;
    }
    (async () => {
      try {
        const accInfo = await loadUserModel(walletAddress);
        const { chainId } = accInfo;
        const {
          otherWallets,
          email,
          kycStatus,
          isUs,
          imageUrl,
          username,
          cloudMessagingToken,
          isAdmin,
          role,
          features,
        } = await getUser();

        const newAuthState = {
          accountInfo: accInfo,
          isAdmin: isAdmin || walletAddress.toLowerCase() === ADMIN_ADDRESS.toLowerCase(),
          isSuperAdmin: walletAddress.toLowerCase() === ADMIN_ADDRESS.toLowerCase(),
          profileWallets: otherWallets || [],
          email,
          idVerified: kycStatus,
          profileIsUs: isUs,
          imageUrl,
          username,
          cloudMessagingToken,
          role,
          features,
        };

        dispatch(setChainId(chainId));
        dispatch(updateAuth({ ...newAuthState }));

        await fetchUserDeals(newAuthState);

        dispatch(updateGlobal({ isInitialLoadFinished: true }));
      } catch (error) {
        logoutOfWeb3Modal();
      }
    })();
  }, [walletAddress, token, fetchUserDeals, dispatch, logoutOfWeb3Modal]);

  useEffect(() => {
    if (activeHashes.length > 0) {
      activeHashes.forEach((tx) => {
        if (!tx.pending) {
          const newHashes = [...activeHashes];
          newHashes.find((x) => x.hash === tx.hash).pending = true;
          dispatch(setActiveHashes(newHashes));
          const provider = getProviderByChainName(tx.chain);
          provider.once(tx.hash, (transaction) => {
            if (transaction.status === 1) {
              if (tx.callback) {
                tx.callback(transaction);
              }
              if (tx.actionType === 'createDeal') {
                const log = transaction.logs.find((l) => l.address === DEAL_FACTORY_ADDRESS);
                const parsedLogs = dealFactoryInterface.parseLog(log);
                dispatch(
                  setResolvedNotification({
                    title: 'Success',
                    description: `Transaction ${formatAddress(tx.hash)} is resolved.`,
                  })
                );
                dispatch(
                  updateNotification({
                    hash: tx.hash,
                    dealAddress: parsedLogs.args.dealAddress,
                    dealImage: parsedLogs.args.imageUrl,
                    status: 'success',
                    statusText: 'Success!',
                  })
                );
              } else {
                dispatch(
                  setResolvedNotification({
                    title: 'Success',
                    description: `Transaction ${formatAddress(tx.hash)} is resolved.`,
                  })
                );
                dispatch(
                  updateNotification({
                    hash: tx.hash,
                    status: 'success',
                    statusText: 'Success!',
                  })
                );
              }
            } else {
              dispatch(
                setResolvedNotification({
                  title: 'Failed',
                  description: `Transaction ${formatAddress(tx.hash)} has failed.`,
                })
              );
              dispatch(
                updateNotification({
                  hash: tx.hash,
                  status: 'failed',
                  statusText: 'Failed!',
                })
              );
            }
            dispatch(removeActiveHash(tx));
          });
        }
      });
    } else {
      dispatch(setSharedNotification({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHashes]);

  useEffect(() => {
    if (walletAddress) {
      loadWeb3Modal();
    }
    notifications.forEach(async (notification) => {
      if (notification.status === 'pending') {
        const provider = getProviderByChainName(notification.chain);
        const result = await provider.getTransactionReceipt(notification.name);
        if (!result) {
          dispatch(
            setActiveHashes([
              ...activeHashes,
              { hash: notification.name, pending: false, chain: notification.chain },
            ])
          );
          return;
        }
        if (result.status === 1) {
          dispatch(
            updateNotification({
              hash: notification.name,
              status: 'success',
              statusText: 'Success!',
            })
          );
        }
        if (result.state === 0) {
          dispatch(
            updateNotification({
              hash: notification.name,
              status: 'failed',
              statusText: 'Failed!',
            })
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoadAppData;
