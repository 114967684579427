import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import CloseDealModal from 'features/deals/CloseDealModal';
import moment from 'moment';
import {
  updateGlobal,
  updateLoading,
  setActiveHashes,
  addNotification,
  removeAnimateDeal,
  setUserDeal,
  updateDeal as updateDealAction,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { switchCurrentNetwork, getNetworkNameById } from 'contracts/browserWallet';
import { cancelDeal, pauseDeal, unpauseDeal } from 'contracts/dealV2Contract';
import CreateClaimerModal from 'features/deals/CreateClaimerModal';
import { getDealStatusClass } from 'utils/helpers';
import NameEditModal from 'components/NameEditModal';
import { useNotifications, useApiService } from 'hooks';
import { updateDeal } from 'services/apiService';
import Tooltip from 'components/tooltip';
import allDealsSingleRemote from 'screens/AllDealsSingle/remote';
import './index.scss';
import Countdown from 'components/countdown';
import DealModelTooltip from 'components/deal-model-tooltip';
import ConfirmDealCancellationModal from 'features/deals/ConfirmDealCancellationModal';
import { getAddressLinkByChainId } from 'contracts/explorers';
import DealInfoDesktop from '../DealInfo';
import ImageEditModal from './ImageEditModal';
import DealManageDropdown from './DealManageDropdown';
import remote from './remote';

const DealRow = ({ deal, onCloseDeal }) => {
  const globalReducer = useSelector((state) => state.global);
  const { chainId, activeHashes, animateDeals } = globalReducer;
  const dispatch = useDispatch();
  const [closeModalOpened, setCloseModalOpened] = useState(false);
  const [nameEditModalOpen, setNameEditModalOpen] = useState(false);
  const [claimerModalOpen, setClaimerModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeInfoTab, setActiveInfoTab] = useState('info');
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const [highlightsModalOpen, setHighlightsModalOpen] = useState(false);
  const [basicMetricsModalOpen, setBasicMetricsModalOpen] = useState(false);
  const [blackDragonDealModalOpen, setBlackDragonDealModalOpen] = useState(false);
  const [raiseStatsModalOpen, setRaiseStatsModalOpen] = useState(false);
  const [partnersModalOpen, setPartnersModalOpen] = useState(false);
  const [vestingClaimInfoModalOpen, setVestingClaimInfoModalOpen] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [projectsModalOpen, setProjectsModalOpen] = useState(false);
  const [imageEditModalOpen, setImageEditModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [adminOverviewModalOpen, setAdminOverviewModalOpen] = useState(false);
  const [vestingBatchInfoModalOpen, setVestingBatchInfoModalOpen] = useState(false);
  const [onUpdateDeal] = useApiService();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { data: pledges } = remote.useGetPledge(deal.id, showDropdown);

  const toggleImageEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    setImageEditModalOpen(!imageEditModalOpen);
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const onNameChangeSubmit = async (name) => {
    try {
      const response = await updateDeal(deal.id, { name });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.name = changedData.name;
      dispatch(setUserDeal(newDeal));
      setNameEditModalOpen(false);
      showSuccessNotification('Updated successfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  const onManage = (e) => {
    if (showDropdown) {
      toggleDrop();
    } else {
      const headerOffset = 135;
      const elementPosition = e.target.closest('.deal-row-top').getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }, 400);
      dispatch(updateGlobal({ activeDeal: deal, dealEditModalAction: 'update' }));
      toggleDrop();
    }
  };

  const onUnpauseDeal = async () => {
    if (deal.status === 'opened') return;
    const networkChecked = await switchCurrentNetwork(chainId, deal.chainId);
    if (networkChecked) {
      dispatch(updateLoading(true));
      const tx = await unpauseDeal(deal.address);
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            { hash: tx.hash, pending: false, chain: getNetworkNameById(deal.chainId) },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: getNetworkNameById(deal.chainId),
            dealAddress: deal.address,
            dealImage: deal.imageUrl,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      } else {
        showErrorNotification();
      }
      dispatch(updateLoading(false));
    } else {
      showErrorNotification(
        `You need to change your network to ${getNetworkNameById(deal.chainId)} to continue.`
      );
    }
  };

  const onPauseDeal = async () => {
    if (deal.status === 'paused') return;
    const networkChecked = await switchCurrentNetwork(chainId, deal.chainId);
    if (networkChecked) {
      dispatch(updateLoading(true));
      const tx = await pauseDeal(deal.address);
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            { hash: tx.hash, pending: false, chain: getNetworkNameById(deal.chainId) },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: getNetworkNameById(deal.chainId),
            dealAddress: deal.address,
            dealImage: deal.imageUrl,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      } else {
        showErrorNotification();
      }
      dispatch(updateLoading(false));
    } else {
      showErrorNotification(
        `You need to change your network to ${getNetworkNameById(deal.chainId)} to continue.`
      );
    }
  };

  const onCancelDeal = async () => {
    if (deal.status === 'canceled') return;
    const networkChecked = await switchCurrentNetwork(chainId, deal.chainId);
    if (networkChecked) {
      dispatch(updateLoading(true));
      const tx = await cancelDeal(deal.address);
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            { hash: tx.hash, pending: false, chain: getNetworkNameById(deal.chainId) },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: getNetworkNameById(deal.chainId),
            dealAddress: deal.address,
            dealImage: deal.imageUrl,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      } else {
        showErrorNotification();
      }
      dispatch(updateLoading(false));
    } else {
      showErrorNotification(
        `You need to change your network to ${getNetworkNameById(deal.chainId)} to continue.`
      );
    }
  };

  const updateOldDealStatus = allDealsSingleRemote.useUpdateVestingStatus(+deal.id);
  const onDistributeDeal = async () => {
    try {
      dispatch(updateLoading(true));
      await updateOldDealStatus.mutateAsync({
        vestingStatusId: 2,
        isOldDeal: false,
      });

      dispatch(
        updateDealAction({
          id: deal.id,
          vestingStatusId: 2,
          vestingStatus: 'Distributed',
          status: 'distributed',
        })
      );

      showSuccessNotification('Deal status updated to distributed!');
    } catch (err) {
      showErrorNotification('Deal status not updated!');
    } finally {
      dispatch(updateLoading(false));
    }
  };

  const onClaimContract = async () => {
    setClaimerModalOpen(true);
  };

  const handleToggleCloseModal = () => {
    setCloseModalOpened(!closeModalOpened);
  };

  const toggleClaimerModal = () => {
    setClaimerModalOpen(!claimerModalOpen);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  const toggleOverviewModal = () => {
    setOverviewModalOpen(!overviewModalOpen);
  };

  const toggleHighlightsModal = () => {
    setHighlightsModalOpen(!highlightsModalOpen);
  };

  const toggleBasicMetricsModal = () => {
    setBasicMetricsModalOpen(!basicMetricsModalOpen);
  };

  const toggleBlackDragonDealModal = () => {
    setBlackDragonDealModalOpen(!blackDragonDealModalOpen);
  };

  const toggleRaiseStatsModal = () => {
    setRaiseStatsModalOpen(!raiseStatsModalOpen);
  };

  const toggleVestingBatchInfoModal = () => {
    setVestingBatchInfoModalOpen(!vestingBatchInfoModalOpen);
  };

  const togglePartnersModal = () => {
    setPartnersModalOpen(!partnersModalOpen);
  };

  const toggleVestingClaimInfoModal = () => {
    setVestingClaimInfoModalOpen(!vestingClaimInfoModalOpen);
  };

  const toggleTeamModal = () => {
    setTeamModalOpen(!teamModalOpen);
  };

  const toggleAdminOverviewModal = () => {
    setAdminOverviewModalOpen(!adminOverviewModalOpen);
  };

  const toggleProjectsModal = () => {
    setProjectsModalOpen(!projectsModalOpen);
  };

  return (
    <>
      {closeModalOpened && (
        <CloseDealModal
          open={closeModalOpened}
          deal={deal}
          onOk={onCloseDeal}
          onClose={handleToggleCloseModal}
          handleToggleCloseModal={handleToggleCloseModal}
        />
      )}
      {imageEditModalOpen && (
        <ImageEditModal
          deal={deal}
          onOk={onUpdateDeal}
          onCancel={toggleImageEditModal}
          defaultValue={deal.imageUrl}
        />
      )}
      {cancelModalOpen && (
        <ConfirmDealCancellationModal
          open={cancelModalOpen}
          onOk={() => {
            onCancelDeal();
            setCancelModalOpen(false);
          }}
          onClose={() => setCancelModalOpen(false)}
          dealName={deal.name}
        />
      )}
      <NameEditModal
        name={deal?.name}
        open={nameEditModalOpen}
        onOk={onNameChangeSubmit}
        onCancel={() => setNameEditModalOpen(false)}
      />
      {claimerModalOpen && (
        <CreateClaimerModal
          open={claimerModalOpen}
          onClose={toggleClaimerModal}
          deal={deal}
          showErrorNotification={showErrorNotification}
        />
      )}
      <div className="deal-holder d-flex full-width">
        <div className="deal-row-top" onClick={onManage}>
          <div
            className={`deal__field deal__field-avatar vertical-center ${getAnimateClass(
              'imageUrl'
            )}`}
          >
            <a
              href={getAddressLinkByChainId(deal.chainId, deal.address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RoundedAvatar src={deal.imageUrl} />
              <RoundedButton
                type="default"
                onClick={toggleImageEditModal}
                className="image-edit-btn"
              >
                Edit
              </RoundedButton>
            </a>
          </div>
          <div
            className={`deal__field deal__field-name vertical-center ${getAnimateClass('name')}`}
          >
            <div>
              <div className="deal-name">
                {/* <CustomInput name="name" value={deal.name} onChange={onInputChange} /> */}
                <span>{deal.name}</span>
                <RoundedButton
                  type="default"
                  onClick={() => setNameEditModalOpen(true)}
                  className="name-edit-btn"
                >
                  Edit
                </RoundedButton>
              </div>
              <CustomProgressBar total={Number(deal.dealSize)} value={Number(deal.raisedAmount)} />
            </div>
          </div>
          <div className="deal__field deal__field-date vertical-center">
            <div>
              <span>{moment(deal.datePublished).format('D-MMM-YYYY')}</span>
            </div>
          </div>
          <div className="deal__field deal__field-date vertical-center">
            <div>
              <span>{deal.dateClosed ? moment(deal.dateClosed).format('D-MMM-YYYY') : ''}</span>
            </div>
          </div>
          <div
            className={`deal__field deal__field-status deal__field-status--${getDealStatusClass(
              deal.status
            )} vertical-center ${getAnimateClass('status')}`}
          >
            <span className="deal__field-status__icon">
              <SvgIcon name="dot" />
            </span>
            <span className="deal__field-status__name">
              {deal.status === 'opened'
                ? deal.raisedAmount === deal.dealSize
                  ? 'filled'
                  : 'live'
                : deal.status === 'distributed'
                ? 'completed'
                : deal.status}
            </span>
          </div>
          <div
            className={`deal__field deal__field-raised-amount vertical-center ${getAnimateClass(
              'raisedAmount'
            )}`}
          >
            <NumberFormat
              value={Number(deal.raisedAmount).toFixed(0)}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          </div>
          <div
            className={`deal__field deal__field-size vertical-center ${getAnimateClass(
              'raisedAmount'
            )}`}
          >
            <NumberFormat
              value={Number(deal.dealSize).toFixed(0)}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          </div>
          <div
            className={`deal__field deal__field-model vertical-center ${getAnimateClass(
              'minAccessLevelLabel'
            )}`}
          >
            <div className="left">
              <span>{deal.minAccessLevelLabel ? deal.minAccessLevelLabel : '-'}</span>
              {deal.phaseEndTimestamp && deal.statusId === 1 && (
                <div className="absolute-div">
                  <Countdown timestamp={deal.phaseEndTimestamp} />
                  {deal.phases[+deal.currentPhaseIndex + 1] && (
                    <Tooltip
                      title={
                        <DealModelTooltip
                          deal={deal}
                          title={`Next phase access level: ${
                            deal.phases[+deal.currentPhaseIndex + 1]?.minAccessLevel
                          }`}
                          timestamp={deal.phaseEndTimestamp}
                        />
                      }
                    >
                      <div className="tooltip-btn">
                        <SvgIcon name="help" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="deal__field deal__field-model vertical-center">
            <div className="left">
              <span>{deal.allocationModel ? deal.allocationModel : '-'}</span>
              {deal.phaseEndTimestamp && deal.statusId === 1 && (
                <div className="absolute-div">
                  <Countdown timestamp={deal.phaseEndTimestamp} />
                  {deal.phases[+deal.currentPhaseIndex + 1] && (
                    <Tooltip
                      title={
                        <DealModelTooltip
                          deal={deal}
                          title={`${deal.phases[+deal.currentPhaseIndex + 1]?.model}`}
                          timestamp={deal.phaseEndTimestamp}
                        />
                      }
                    >
                      <div className="tooltip-btn">
                        <SvgIcon name="help" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="deal__field deal__field-status-stepper vertical-center">
            <Tooltip title="Deal open for contributions.">
              <span
                className={`deal__field-status-step ${
                  deal.status === 'opened' ? 'deal__field-status-step--opened--active' : ''
                }`}
              >
                <IconButton icon="statusOpened" onClick={onUnpauseDeal} />
              </span>
            </Tooltip>
            <Tooltip title="New contributions paused, existing ones are still present..">
              <span
                className={`deal__field-status-step ${
                  deal.status === 'paused' ? 'deal__field-status-step--paused--active' : ''
                }`}
              >
                <IconButton icon="statusPaused" onClick={onPauseDeal} />
              </span>
            </Tooltip>
            <Tooltip title="Close the deal for new contributions and send funds to Admin deal wallet.">
              <span
                className={`deal__field-status-step ${
                  deal.status === 'closed' ? 'deal__field-status-step--closed--active' : ''
                }`}
              >
                <IconButton icon="statusClosed" onClick={handleToggleCloseModal} />
              </span>
            </Tooltip>
            <Tooltip title="Deal inactive, collected funds available for refund.">
              <span
                className={`deal__field-status-step ${
                  deal.status === 'canceled' ? 'deal__field-status-step--canceled--active' : ''
                }`}
              >
                <IconButton icon="statusCanceled" onClick={() => setCancelModalOpen(true)} />
              </span>
            </Tooltip>
            <Tooltip title="Deal inactive, all tokens delivered.">
              <span
                className={`deal__field-status-step ${
                  deal.status === 'distributed'
                    ? 'deal__field-status-step--distributed--active'
                    : ''
                }`}
              >
                <IconButton icon="statusDistributed" onClick={onDistributeDeal} />
              </span>
            </Tooltip>
            <Tooltip title="Deploy a smart contracts for platform claiming.">
              <span
                className={`deal__field-status-step claimer ${
                  deal.status === 'closed' ? 'deal__field-status-step--deployClaimer--active' : ''
                }`}
              >
                <IconButton
                  icon="iconClaimContract"
                  disabled={deal.status !== 'closed'}
                  onClick={onClaimContract}
                />
              </span>
            </Tooltip>
            <RoundedButton type="primary">{showDropdown ? 'Update' : 'Manage'}</RoundedButton>
            <DealManageDropdown
              deal={deal}
              showHide
              showDuplicate={[3, 4, 5].includes(deal.statusId)}
            />
          </div>
        </div>

        <div className={`deal-info-mobile${showDropdown ? ' show' : ''}`}>
          <div className="deal-info-mobile-box">
            <p>Status</p>
            <h3>Deals</h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Filled</p>
            <h3>
              <NumberFormat
                value={Number(deal.raisedAmount).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Deal size</p>
            <h3>
              <NumberFormat
                value={Number(deal.dealSize).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Personal Max</p>
            <h3>
              <NumberFormat
                value={Number(deal.userCap).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>My contribution</p>
            <h3>
              <NumberFormat
                value={Number(deal.userCap).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-actions">
            <RoundedButton type="primary" onClick={onManage}>
              Manage
            </RoundedButton>
          </div>
        </div>

        <DealInfoDesktop
          showDropdown={showDropdown}
          activeInfoTab={activeInfoTab}
          setActiveInfoTab={setActiveInfoTab}
          overviewModalOpen={overviewModalOpen}
          toggleOverviewModal={toggleOverviewModal}
          highlightsModalOpen={highlightsModalOpen}
          toggleHighlightsModal={toggleHighlightsModal}
          basicMetricsModalOpen={basicMetricsModalOpen}
          toggleBasicMetricsModal={toggleBasicMetricsModal}
          blackDragonDealModalOpen={blackDragonDealModalOpen}
          toggleBlackDragonDealModal={toggleBlackDragonDealModal}
          raiseStatsModalOpen={raiseStatsModalOpen}
          toggleRaiseStatsModal={toggleRaiseStatsModal}
          partnersModalOpen={partnersModalOpen}
          togglePartnersModal={togglePartnersModal}
          vestingClaimInfoModalOpen={vestingClaimInfoModalOpen}
          toggleVestingClaimInfoModal={toggleVestingClaimInfoModal}
          teamModalOpen={teamModalOpen}
          toggleTeamModal={toggleTeamModal}
          projectsModalOpen={projectsModalOpen}
          adminOverviewModalOpen={adminOverviewModalOpen}
          toggleAdminOverviewModal={toggleAdminOverviewModal}
          toggleProjectsModal={toggleProjectsModal}
          vestingBatchInfoModalOpen={vestingBatchInfoModalOpen}
          toggleVestingBatchInfoModal={toggleVestingBatchInfoModal}
          deal={deal}
          pledges={pledges}
        />
      </div>
    </>
  );
};

DealRow.propTypes = {
  deal: PropTypes.shape(),
};

DealRow.defaultProps = {
  deal: {},
};

export default React.memo(DealRow);
