import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Deals from 'screens/Deals';
import Lockup from 'screens/Lockup';
import UserProfile from 'screens/UserProfile';
import Whitelists from 'screens/Whitelists';
import MyDeals from 'screens/MyDeals';
import Pledges from 'screens/Pledges';
import EmailVerify from 'screens/EmailVerify';
import RoleManagement from 'screens/RoleManagement';
import Polls from 'screens/Polls';
import Drafts from 'screens/Drafts';
import Invoices from 'screens/Invoices';
import Analytics from 'screens/Analytics';
import Dashboard from 'screens/Dashboard';
import DistroWallets from 'screens/DistroWallets';
import CustomClaims from 'screens/CustomClaims';
import Balances from 'screens/Balances';
import AllDeals from 'screens/AllDeals';
import History from 'screens/History';
import AllDealsSingle from 'screens/AllDealsSingle';
import Allowance from 'screens/Allowance';
import { useSelector } from 'react-redux';
import { ROLES } from 'features/sidebar/LeftSideBar/utils';

function AppRouter() {
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin: hasRole, features, role } = authReducer;

  const isUserOrAdmin = (featureName) => {
    return role === ROLES.ADMIN || !hasRole || (hasRole && features.includes(featureName));
  };

  const isUser = (featureName) => {
    return !hasRole || (hasRole && features.includes(featureName));
  };

  const isAdmin = (featureName) => {
    return role === ROLES.ADMIN || (hasRole && features.includes(featureName));
  };

  return (
    <Switch>
      {role && isUserOrAdmin('deals list') && (
        <Route
          path="/pledges"
          render={() => {
            return <Pledges />;
          }}
        />
      )}
      {role && isUserOrAdmin('deals list') && (
        <Route
          path="/deals"
          render={() => {
            return <Deals />;
          }}
        />
      )}
      {role && isAdmin('distribution') && (
        <Route
          path="/distros"
          exact
          render={() => {
            return <AllDeals />;
          }}
        />
      )}
      {role && isAdmin('distribution') && (
        <Route
          path="/history"
          render={() => {
            return <History />;
          }}
        />
      )}
      {role && isAdmin('distribution') && (
        <Route
          path="/all-deals/:dealId"
          render={() => {
            return <AllDealsSingle />;
          }}
        />
      )}
      {role && isUser('lockup') && (
        <Route
          path="/lockup"
          render={() => {
            return <Lockup />;
          }}
        />
      )}
      {role && isUser('allowance') && (
        <Route
          path="/allowance"
          render={() => {
            return <Allowance />;
          }}
        />
      )}
      {role && isUser('profile settings') && (
        <Route
          path="/profile"
          render={() => {
            return <UserProfile />;
          }}
        />
      )}
      {role && isAdmin('deals list') && (
        <Route
          path="/drafts"
          render={() => {
            return <Drafts />;
          }}
        />
      )}
      {role && isAdmin('whitelists') && (
        <Route
          path="/whitelists"
          render={() => {
            return <Whitelists />;
          }}
        />
      )}
      <Route
        path="/verify-email"
        render={() => {
          return <EmailVerify />;
        }}
      />
      {role && isUserOrAdmin('polls') && (
        <Route
          path="/polls"
          render={() => {
            return <Polls />;
          }}
        />
      )}
      {role && isUserOrAdmin('invoices') && (
        <Route
          path="/invoices"
          render={() => {
            return <Invoices />;
          }}
        />
      )}
      {role && isAdmin('user manager') && (
        <Route
          path="/role-management"
          render={() => {
            return <RoleManagement />;
          }}
        />
      )}
      {role && (isUser('my analytics') || isUserOrAdmin('dashboard')) && (
        <Route
          path="/analytics/:dealId"
          render={() => {
            return <Analytics />;
          }}
        />
      )}
      {role && isUser('my analytics') && (
        <Route
          path="/analytics"
          render={() => {
            return <Analytics />;
          }}
        />
      )}
      {role && isUserOrAdmin('dashboard') && (
        <Route
          path="/dashboard"
          render={() => {
            return <Dashboard />;
          }}
        />
      )}
      {role && isAdmin('seeds and wallets') && (
        <Route
          path="/seeds"
          render={() => {
            return <DistroWallets />;
          }}
        />
      )}
      {role && isAdmin('custom claims') && (
        <Route
          path="/custom-claims"
          render={() => {
            return <CustomClaims />;
          }}
        />
      )}
      {role && isAdmin('balances') && (
        <Route
          path="/wallets"
          render={() => {
            return <Balances />;
          }}
        />
      )}
      <Route
        path="/"
        render={() => {
          return <MyDeals />;
        }}
      />
    </Switch>
  );
}

export default AppRouter;
