import { useDispatch } from 'react-redux';
import {
  setUserDeals,
  setAlertNotification,
  updateFetchingDeals,
  setOldDeals,
} from 'store/actions';
import { ADMIN_ADDRESS } from 'constants/index';
import { getAllAdminDeals, getAllDeals, getAllOldDeals } from 'services/apiService';
import { useCallback } from 'react';
import { getOldDealModels } from '../models/oldDealModel';
import { getAdminDealModels, getDealModels } from '../models/dealModel';

const useLoadDeals = () => {
  const dispatch = useDispatch();

  const loadPlatformDeals = useCallback(
    async (newAuthState) => {
      try {
        const apiDeals = newAuthState.isAdmin ? await getAllAdminDeals() : await getAllDeals();
        const deals = newAuthState.isAdmin
          ? await getAdminDealModels(apiDeals)
          : await getDealModels(newAuthState, apiDeals);

        if (
          newAuthState.idVerified === 'verification_approved' ||
          newAuthState.idVerified === 'approved' ||
          newAuthState.isAdmin
        ) {
          dispatch(setUserDeals(deals));
          return;
        }

        const filteredDeals = deals.filter(
          ({ contributedAmount }) => contributedAmount && +contributedAmount
        );
        dispatch(setUserDeals(filteredDeals));
      } catch (error) {
        console.error(error);
        dispatch(
          setAlertNotification({
            status: 'error',
            title: 'Error',
            description: 'Could not load platform deals.',
          })
        );
      }
    },
    [dispatch]
  );

  const loadOldDeals = useCallback(
    async (newAuthState) => {
      if (newAuthState.accountInfo.address.toLowerCase() === ADMIN_ADDRESS.toLowerCase()) {
        return;
      }

      try {
        const oldDealsApi = await getAllOldDeals();
        const oldDeals = await getOldDealModels(newAuthState.accountInfo.address, oldDealsApi);
        dispatch(setOldDeals(oldDeals || []));
      } catch (error) {
        console.error(error);
        dispatch(
          setAlertNotification({
            status: 'error',
            title: 'Error',
            description: 'Could not load old deals.',
          })
        );
      }
    },
    [dispatch]
  );

  const fetchUserDeals = useCallback(
    async (newAuthState) => {
      dispatch(updateFetchingDeals(true));
      await Promise.all([loadPlatformDeals(newAuthState), loadOldDeals(newAuthState)]);
      dispatch(updateFetchingDeals(false));
    },
    [loadOldDeals, loadPlatformDeals, dispatch]
  );

  return { fetchUserDeals };
};

export default useLoadDeals;
