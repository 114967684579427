/* eslint-disable import/prefer-default-export */

import { ethers, utils } from 'ethers';
import {
  ETHEREUM_CHAIN_NAME,
  BSC_CHAIN_NAME,
  POLYGON_CHAIN_NAME,
  AVALANCHE_CHAIN_NAME,
  BASE_CHAIN_NAME,
  MERLIN_CHAIN_NAME,
  ARBITRUM_CHAIN_NAME,
} from 'constants/config';
import moment from 'moment';
import numeral from 'numeral';

export const getTokensExpected = (dealSize, dealFee, dealPrice) => {
  return (dealSize * (100 - dealFee)) / 100 / dealPrice;
};

export const thousandSeparator = (num) => {
  return (Math.floor(Number(num) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const thousandSeparatorRound = (num) => {
  return (Math.floor(Number(num) * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const decimalThousandSeparator = (amount) => {
  if (amount === '0') return '';
  const [wholePart, decimalPart] = amount.split('.');
  const wholePartFormatted = wholePart
    .split(',')
    .join('')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${wholePartFormatted}${decimalPart || decimalPart === '' ? `.${decimalPart}` : ''}`;
};

export const formatAddress = (address) => {
  if (!address) return '';
  const first = address.substring(0, 8);
  const last = address.substring(address.length - 5, address.length);
  return `${first}...${last}`;
};

export const getYears = (startYear) => {
  const currentDate = new Date();
  const years = [];
  for (let i = startYear; i <= currentDate.getFullYear(); i++) {
    years.push(i.toString());
  }
  years.push('All');
  return years;
};

export const roundNumberToDecimals = (num, decimals) => {
  if (!num && num !== 0) return '';
  const decimalsHundreds = Math.pow(10, decimals);
  return Number((Math.floor(Number(num) * decimalsHundreds) / decimalsHundreds).toFixed(decimals));
};

export const addStringAmounts = (stringAmount1 = '0.0', stringAmount2 = '0.0', decimals) => {
  return utils.formatUnits(
    utils.parseUnits(stringAmount1, decimals).add(utils.parseUnits(stringAmount2, decimals)),
    decimals
  );
};

export const subStringAmounts = (stringAmount1, stringAmount2, decimals) => {
  return utils.formatUnits(
    utils.parseUnits(stringAmount1, decimals).sub(utils.parseUnits(stringAmount2, decimals)),
    decimals
  );
};

export const addStringAndBigNumberAmounts = (stringAmount1, bnAmount2, decimals) => {
  return utils.formatUnits(utils.parseUnits(stringAmount1, decimals).add(bnAmount2), decimals);
};

export const subStringAndBigNumberAmounts = (stringAmount1, bnAmount2, decimals) => {
  return utils.formatUnits(utils.parseUnits(stringAmount1, decimals).sub(bnAmount2), decimals);
};

export const getWantedNetwork = (network) => {
  switch (network) {
    case 'ethereum':
      return ETHEREUM_CHAIN_NAME;
    case 'bsc':
      return BSC_CHAIN_NAME;
    case 'polygon':
      return POLYGON_CHAIN_NAME;
    case 'avalanche':
      return AVALANCHE_CHAIN_NAME;
    case 'base':
      return BASE_CHAIN_NAME;
    case 'merlin':
      return MERLIN_CHAIN_NAME;
    case 'arbitrum':
      return ARBITRUM_CHAIN_NAME;
    default:
      return network;
  }
};

export const getChangedProperties = (oldObject, newObject) => {
  const fields = [];
  for (const [key, value] of Object.entries(newObject)) {
    if (value !== oldObject[key]) {
      fields.push(key);
    }
  }

  return fields;
};

// eslint-disable-next-line no-extend-native
String.prototype.replaceAt = function (index, replacement) {
  if (index >= this.length) {
    return this.valueOf();
  }

  return this.substring(0, index) + replacement + this.substring(index + 1);
};

export const formatOverviewText = (text) => {
  let countAsterisk = 0;
  let countDegree = 0;
  for (let i = 0; i <= text.length; i++) {
    if (text.charAt(i) === '*') {
      countAsterisk += 1;
      if (countAsterisk % 2 === 0) {
        text = text.replaceAt(i, '</b>');
      } else {
        text = text.replaceAt(i, '<b>');
      }
    }
    if (text.charAt(i) === '°') {
      countDegree += 1;
      if (countDegree % 2 === 0) {
        text = text.replaceAt(i, '</li></ul>');
      } else {
        text = text.replaceAt(i, '<ul><li>');
      }
    }
  }
  return text;
};

export const getDealStatusClass = (status) => {
  switch (status) {
    case 'Pledge Opened':
    case 'pledge opened':
      return 'PledgeOpened';
    case 'Pledge Closed':
    case 'pledge closed':
      return 'PledgeClosed';
    default:
      return status;
  }
};

export const sortDeals = (field, deals, direction, isPledge, isNumber, defaultSort) => {
  let _deals = isPledge ? [...deals.filter((x) => [7, 8].includes(x.statusId))] : [...deals];
  if (isPledge && field === 'dateCreated') {
    _deals = _deals.map((deal) =>
      deal.dateCreated ? deal : { ...deal, dateCreated: deal.createdAt }
    );
  }

  let sortedDeals = [];

  if (direction === 'desc') {
    sortedDeals = isNumber
      ? _deals.sort((a, b) => (Number(a[field]) < Number(b[field]) ? -1 : 1))
      : _deals.sort((a, b) => {
          if (!a[field]) return 1;
          if (!b[field]) return -1;
          return a[field]?.toLowerCase() < b[field]?.toLowerCase() ? -1 : 1;
        });
  } else if (direction === 'asc') {
    sortedDeals = isNumber
      ? _deals.sort((a, b) => (Number(a[field]) < Number(b[field]) ? 1 : -1))
      : _deals.sort((a, b) => {
          if (!a[field]) return 1;
          if (!b[field]) return -1;
          return a[field]?.toLowerCase() < b[field]?.toLowerCase() ? 1 : -1;
        });
  } else if (defaultSort) {
    sortedDeals = _deals.sort(defaultSort);
  } else {
    sortedDeals = _deals.sort((a, b) =>
      moment(a.createdAt).valueOf() < moment(b.createdAt).valueOf() ? 1 : -1
    );
  }
  return sortedDeals;
};

export const checkEmail = (emailAddress) => {
  const emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return !!emailFilter.test(emailAddress);
};

export const getNativeTokenName = (network) => {
  switch (network) {
    case 'ethereum':
    case 'base':
    case 'arbitrum':
      return 'ETH';
    case 'sepolia':
    case 'ethereum-sepolia':
    case 'arbitrum-sepolia':
      return 'SepoliaETH';
    case 'xDai':
      return 'XDAI';
    case 'bsc':
    case 'bsc-testnet':
      return 'BNB';
    case 'polygon':
    case 'polygon-testnet':
      return 'MATIC';
    case 'avalanche':
    case 'avalanche-testnet':
      return 'AVAX';
    case 'merlin':
      return 'BTC';

    default:
      return '';
  }
};

export const formatFiatPrice = (price, isBN) => {
  const formattedNumber = isBN ? +ethers.utils.formatUnits(price, 6) : +price;
  return numeral(formattedNumber).format('0,0.00');
};

export const formatChartPrice = (price) => {
  const formattedNumber = +price;
  const decimals = numeral(formattedNumber).format('0,0.000000').split('.')[1];
  let numberOfDecimals = 2;
  if (decimals) {
    const firstWholeNumber = parseInt(decimals, 10).toString().charAt(0);
    if (firstWholeNumber !== -1) {
      numberOfDecimals += decimals.indexOf(firstWholeNumber);
    }
  }
  let format = '0,0.';
  for (let i = 0; numberOfDecimals > i; i++) {
    format += '0';
  }

  const returnFormat =
    formattedNumber < 1
      ? numeral(formattedNumber).format(format)
      : numeral(formattedNumber).format('0,0.00');
  return returnFormat;
};

export const formatTokenPrice = (price, isBN, decimals = 18) => {
  const formattedNumber = isBN
    ? +ethers.utils.formatUnits(price.toLocaleString('fullwide', { useGrouping: false }), decimals)
    : +price;
  return numeral(formattedNumber).format('0,0.00');
};

export const validateTxHash = (hash) => /^0x([A-Fa-f0-9]{64})$/.test(hash);
